import classNames from 'classnames';
import { useSelector } from 'react-redux';
// Player
import { PlayPauseControlButton, SoundControlButton } from '@canalplus/oneplayer-shared-components';
// Dive
import { Button } from '@canalplus/dive';
// Constants
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { Binder, MiddlewareFactory } from '@canalplus/one-navigation';
// Store
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
// Components
import Linker from '../../../../../../components/Linker/Linker';
import { Actions as VideoActions } from '../../../../../../components/Video';
// Types
import ThumborMediaImage from '../../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { PromotionStrateContentData } from '../../../../../../templates/LandingV5/components/PromotionStrate/data/types';
// Styles
import styles from './InfoLayer.css';

export type InfoLayerProps = Pick<
  PromotionStrateContentData,
  'altLogoChannel' | 'altLogoType' | 'button' | 'catchlines' | 'URLLogoChannel' | 'URLLogoType'
> & {
  /**
   * If true, the button will be automatically focused on mount on TV
   * e. g. When promotion strate is a cover and no header shown
   */
  forceFocusOnMount?: boolean;
  /**
   * If true, the related video has been fully watched. The elements that should be displayed only at the end of the video will be displayed.
   * e. g. The video has fully watched, then the user changes page and goes back to this one. The elements are displayed.
   */
  canDisplayAllElem: boolean;
  /**
   * Middleware of Binder
   */
  middleware: MiddlewareFactory[];
};

function InfoLayer({
  altLogoChannel,
  altLogoType,
  button,
  canDisplayAllElem,
  catchlines,
  URLLogoChannel,
  URLLogoType,
  middleware,
  forceFocusOnMount = false,
}: InfoLayerProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);

  return (
    <div className={styles.infoLayer}>
      <Binder middleware={middleware} forceFocusOnMount={forceFocusOnMount}>
        <div className={classNames(styles.infoLayer__row, styles.infoLayer__row__top)}>
          <div className={styles.infoLayer__main}>
            {canDisplayAllElem && URLLogoType && (
              <h3
                className={classNames(styles.infoLayer__title, styles.infoLayer__fadeIn)}
                {...(!altLogoType && { 'aria-hidden': true })}
              >
                <ThumborMediaImage
                  alt={altLogoType}
                  className={styles.infoLayer__logo}
                  dimensions={isTvDevice ? DIMENSIONS.LOGO_TITLE_V5_TV : DIMENSIONS.LOGO_TITLE_V5}
                  url={URLLogoType}
                  width="auto"
                  {...(!altLogoType && { 'aria-hidden': true })}
                />
              </h3>
            )}
            {canDisplayAllElem &&
              catchlines.map((catchline) => (
                <p
                  key={`catchline-${catchline.type}`}
                  className={classNames(styles[`infoLayer__catchline-${catchline.type}`], styles.infoLayer__fadeIn)}
                >
                  {catchline.label}
                </p>
              ))}
          </div>
          {!isTvDevice && (
            <div className={styles.infoLayer__player_buttons}>
              <VideoActions>
                <PlayPauseControlButton variant="video" />
                <SoundControlButton variant="video" />
              </VideoActions>
            </div>
          )}
        </div>
        <div className={styles.infoLayer__row}>
          {button && (
            <div className={styles.infoLayer__button}>
              <Button
                renderWrapper={({ id, className, children, disabled }) => (
                  <Linker
                    ariaLabel={button.ariaLabel}
                    className={className}
                    data={{
                      mainOnClick: button.onClick,
                      ...(button.onClick.contentID && { contentID: button.onClick.contentID }),
                      ...(button.onClick.EpgId && { EpgId: button.onClick.EpgId }),
                      context: button.context,
                    }}
                    disabled={disabled}
                    id={id}
                  >
                    {children}
                  </Linker>
                )}
              >
                {button.label}
              </Button>
            </div>
          )}
          {URLLogoChannel && (
            <ThumborMediaImage
              alt={altLogoChannel}
              className={styles.infoLayer__channelLogo}
              dimensions={DIMENSIONS.LOGO_V5}
              url={URLLogoChannel}
              width="auto"
            />
          )}
        </div>
      </Binder>
    </div>
  );
}

export default InfoLayer;
